<template>
  <div class="card p-5">
    <div class="card-header border-0 px-0">
      <div class="card-title">
        <h1 class="card-label">Choose Province</h1>
      </div>
      <div class="d-flex align-items-center">
        <router-link to="/location/add-node" class="btn btn-success me-4">
          Create Node
        </router-link>
      </div>
    </div>

    <el-select
      clearable
      filterable
      placeholder="Select Province"
      v-model="selectedProvince"
      :loading="isLoading"
      @keyup.enter="goToNodeList"
    >
      <el-option
        :key="i"
        v-for="(province, i) in provinces"
        :value="province.id"
        :label="province.name"
      />
    </el-select>
    <div class="d-flex justify-content-end mt-4">
      <button
        @click="goToNodeList"
        :disabled="!selectedProvince"
        class="btn btn-primary"
      >
        Filter
      </button>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';

export default {
  name: 'NodeProvince',
  setup() {
    const state = reactive({
      isLoading: true,
      provinces: [],
      selectedProvince: '',
    });
    const router = useRouter();

    onMounted(async () => {
      const { data } = await ApiService.get('location/province?pageSize=100');
      state.provinces = data.datas;

      setCurrentPageBreadcrumbs('Location', ['Node Province'], {});

      state.isLoading = false;
    });

    const goToNodeList = () => {
      if (!state.selectedProvince) {
        return;
      }

      router.push(`/location/node-province/${state.selectedProvince}`);
    };

    return { ...toRefs(state), goToNodeList };
  },
};
</script>

<style></style>
